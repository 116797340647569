@use '../../../../scss/settings/breakpoints';

.plf-register-infos {
  .plf-widget-video .plf-video {
    height: auto;
    padding-bottom: 60%;
    width: 100%;
  }

  .sv-slot-content {
    padding: var(--spacer-xl) var(--spacer-s);

    p:last-child {
      margin-bottom: 0;
    }
  }

  .sv-slot .sv-widget-content-content h2 {
    padding-bottom: 1.25rem;
    position: relative;
    margin-top: 0;

    &::after {
      background-color: var(--color-orange);
      bottom: 0;
      content: '';
      height: var(--headline-hightlight-line-height);
      left: 0;
      position: absolute;
      width: var(--headline-hightlight-line-width);
    }
  }

  @media (min-width: breakpoints.$large) {
    margin-left: 0;

    .sv-slot-content {
      padding: var(--spacer-xl) var(--spacer-s) var(--spacer-l) 0;
    }
  }
}

.plf-video-frontpage-wrapper {
  .plf-widget-video {
    margin-bottom: var(--spacer-xl);

    .plf-video {
      height: 66%;
      margin: 0 auto;
      padding-bottom: 37%;
      width: 66%;
    }
  }
}

.sv-slot-faq {
  .a-section {
    margin-left: var(--spacer-s);
    margin-right: var(--spacer-s);
  }
}
