@use '../../../../../scss/functions' as *;
$svg-path: '/design/1/img/form-svg/';

.a-select {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacer-xs);

  &__label {
    margin-bottom: var(--spacer-xxs);
  }

  select {
    appearance: none;
    background: url('#{$svg-path}/arrow_drop_down_orange.svg') right 0.75rem center no-repeat var(--color-gray-light);
    background-size: 1.25rem;
    border: 0;
    color: var(--color-gray-dark);
    opacity: 1;
    padding: 0.5rem 1.75rem 0.5rem 1rem;
    -webkit-text-fill-color: var(--color-gray-dark);
  }

  select:active,
  select:focus-visible,
  select:focus {
    color: var(--color-orange);
    outline: none;

    option {
      opacity: 1;
      color: var(--color-gray-dark);
      -webkit-text-fill-color: var(--color-gray-dark);
    }
  }

  select:disabled {
    background: url('#{$svg-path}/arrow_drop_down_gray.svg') right 0.75rem center no-repeat var(--color-gray-light);
    border-bottom: 1px solid var(--color-gray);
  }

  &__msg {
    color: var(--color-red);
    padding: remValue(5);
  }
}
