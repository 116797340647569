@mixin transform-btn-chevron($fill-color) {
  &:disabled:hover .a-btn-chevron__wrapper,
  &:disabled .a-btn-chevron__wrapper{
    transform: none;

    svg {
      fill: var(--color-gray);
    }
  }

  &:hover .a-btn-chevron__wrapper {
    transform: translateY(-50%);

    svg {
      fill: $fill-color;
    }
  }
}

@mixin transform-btn-primary() {
  &:hover .a-button::after {
    height: 100%;
    opacity: 1;
    transform: scaleY(1);
    width: 100%;
  }

  &:hover .a-button {
    color: var(--color-white);
  }

  &.a-button::after {
    background-color: var(--color-orange);
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    transform: scaleY(0);
    transform-origin: bottom center;
    transition: transform 0.3s, opacity 0.3s;
    width: 100%;
    z-index: -1;
  }
}
