@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/design/1/fonts/roboto-regular-400.woff2') format('woff2'), url('/design/1/fonts/roboto-regular-400.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('/design/1/fonts/roboto-regular-italic-400.woff2') format('woff2'), url('/design/1/fonts/roboto-regular-italic-400.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/design/1/fonts/roboto-light-300.woff2') format('woff2'), url('/design/1/fonts/roboto-light-300.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('/design/1/fonts/roboto-light-300italic.woff2') format('woff2'), url('/design/1/fonts/roboto-light-300italic.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/design/1/fonts/roboto-bold-700.woff2') format('woff2'), url('/design/1/fonts/roboto-bold-700.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('/design/1/fonts/roboto-bold-700italic.woff2') format('woff2'), url('/design/1/fonts/roboto-bold-700italic.woff') format('woff');
}
