@use '../../../../scss/functions' as *;

.plf-print-detail-section,
.plf-print-detail-section-safari {
  .plf-header-print-pdf {
    height: 0.8cm;
    margin-bottom: 1.75cm;
    margin-top: 0.8cm;
    text-align: right;
  }

  .plf-footer-print-pdf {
    margin-top: 0.5cm;
    margin-bottom: 0.8cm;

    .plf-footer-print-pdf-bottom {
      font-family: Calibri, sans-serif;
      font-size: remValue(14.5);
      line-height: remValue(14.5);
    }

    .plf-footer-print-pdf-top {
      font-family: Arial, sans-serif;
      font-size: remValue(16);
      line-height: remValue(16);
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
}

.plf-print-detail-section-safari {
  .plf-footer-print-pdf {
    margin-top: 0.8cm;
    position: relative;

    .plf-footer-print-pdf-bottom {
      font-size: remValue(14);
      line-height: remValue(14);
    }

    .plf-footer-print-pdf-top {
      font-size: remValue(15);
      line-height: remValue(15);
    }
  }
}
