.plf-sub-content {
  position: relative;

  h2 {
    scroll-margin-top: var(--scroll-top-height);
    margin-top: var(--spacer-l);

    &:not([id]) {
      a[id] {
        display: inline-block;
        scroll-margin-top: var(--scroll-top-height);
      }
    }
  }
}

.plf-sidenav {
  margin-bottom: var(--spacer-l);
  margin-top: var(--spacer-l);
  padding-left: var(--spacer-xs);
  padding-right: var(--spacer-xs);
  padding-top: var(--spacer-xxs);
  position: sticky;
  top: var(--scroll-top-height);
}
