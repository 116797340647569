@keyframes content-smooth-in {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes border-top-load {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes nav-slide-in {
  100% {
    transform: translateY(0);
  }
}
