@use '../../../../../scss/functions' as *;

@mixin input() {
  input {
    background-color: var(--color-gray-light);
    border: 0;
    border-bottom: 1px solid var(--color-orange);
    box-shadow: none;
    color: var(--color-gray-dark);
    opacity: 1;
    padding: var(--spacer-xxs) var(--spacer-xs) calc(var(--spacer-xxs) - 1px);
    -webkit-text-fill-color: var(--color-gray-dark);
    width: 100%;

    &:not(:disabled):active,
    &:not(:disabled):focus {
      border-bottom-width: remValue(2);
      color: var(--color-dark);
      outline: 0;
      padding-bottom: calc(var(--spacer-xxs) - #{remValue(2)});
    }

    &:disabled {
      border-bottom: 1px solid var(--color-gray);
    }

    &::placeholder {
      color: var(--color-gray-dark);
      opacity: 1;
    }

    &[type="date"] {
      padding-right: var(--spacer-s);
    }
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .a-icon {
    height: var(--icon-size);
    width: var(--icon-size);
  }

  &.-touched {
    &.-error {
      margin-bottom: 0;

      input {
        border-bottom-color: var(--color-red);
      }

      .a-icon svg {
        fill: var(--color-red);
      }
    }

    &:not(.-error) {
      input {
        border-bottom-color: var(--color-green);
      }

      .a-icon svg {
        fill: var(--color-green);
      }
    }
  }

  .a-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__input-icon {
    left: 0.5rem;

    svg {
      fill: var(--color-orange);
    }
  }

  &.-icon {
    input {
      padding-left: calc(1rem + var(--icon-size));
    }
  }

  &__success-icon {
    right: 0.5rem;

    svg {
      fill: var(--color-green);
    }
  }

  &__error-icon {
    right: 0.5rem;

    svg {
      fill: var(--color-red);
    }
  }

  &__msg {
    color: var(--color-red);
    padding: remValue(5);
  }

  &.-loading::after {
    animation: loadInputSpinner 1.25s infinite linear;
    border-color: var(--color-gray);
    border-radius: 50%;
    border-style: solid;
    border-top-color: var(--color-orange);
    border-width: var(--loadspinner-border-size);
    content: '';
    font-size: 0.55rem;
    height: var(--icon-size);
    margin: 0;
    position: absolute;
    right: remValue(8);
    scroll-margin-top: var(--scroll-top-height);
    text-indent: -9999em;
    top: 50%;
    transform: translateY(-50%);
    width: var(--icon-size);
  }
}

@keyframes loadInputSpinner {
  from {
    transform: translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateY(-50%) rotate(360deg);
  }
}
