@mixin currency {
  &__currency {
    margin-left: var(--spacer-xxs);
  }
}

@mixin simulation-form {
  &__submitWrapper {
    justify-content: flex-end;
  }
}

@mixin simulation-calculated {
  @include currency;

  &__tableHead.row {
    padding-top: 0;
  }

  &__tableHead > * {
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      justify-content: flex-start;
    }
  }

  &__columnDiff {
    color: var(--color-green-light);
  }

  &__buttonWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacer-xs);
  }

  &__requestButton {
    margin-left: var(--spacer-xs);
  }
}
