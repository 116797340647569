@use '../../../../scss/functions' as *;

.o-header {
  &__borderTop {
    animation: border-top-load 0.7s linear forwards;
    background-color: var(--color-orange);
    height: var(--highlight-top-border-size);
    position: absolute;
    z-index: z-index(page-layout, borderTop);
  }

  &__wrapper {
    animation: nav-slide-in 0.5s 0.7s ease-out forwards;
    padding-top: var(--highlight-top-border-size);
    transform: translateY(-100%);
  }
}
