@use '../../../../../../../../design/1/scss/settings/breakpoints';

@mixin answerForm() {
  &.row:last-of-type {
    margin-bottom: 0;
  }

  &__answerButton {
    justify-content: center;
    align-self: flex-start;
    flex-grow: 1;
    margin-top: calc(#{var(--font-display3-line-height)} + #{var(--spacer-xxs)});

    @media (min-width: breakpoints.$small) {
      margin-left: var(--spacer-xxs);
    }
  }

  &:first-of-type {
    margin-top: var(--spacer-m);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &__textarea,
  &__fileUpload {
    padding-left: 0;
    padding-right: 0;
  }
}
