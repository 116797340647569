@use 'breakpoints';
@use '../functions' as *;

:root {
  --size-gutter: 0;

  --spacer-xxl: 6rem;
  --spacer-xl: 4rem;
  --spacer-l: 3rem;
  --spacer-m: 2.5rem;
  --spacer-s: 2rem;
  --spacer-xs: 1rem;
  --spacer-xxs: 0.5rem;
  --spacer-xxxs: 0.25rem;

  --icon-size: #{remValue(24)};

  --chevron-icon-size: #{remValue(40)};
  --chevron-icon-size-menu: #{remValue(30)};
  --chevron-icon-size-standalone: 2.3rem;

  --info-circle-size: #{remValue(125)};

  --headline-hightlight-line-height: 0.25rem;
  --headline-hightlight-line-width: 2rem;

  --header-toprow-height: 1.5rem;
  --header-bottomrow-height: 4rem;
  --header-bottomrow-logo-height-logocentered: 3rem;
  --header-bottomrow-height-logocentered: 7rem;
  --header-total-height: calc(var(--header-toprow-height) + var(--header-bottomrow-height));
  --header-total-height-logocentered: calc(var(--header-toprow-height) + var(--header-bottomrow-height-logocentered));
  --header-news-indicator-size: 0.75rem;
  --header-news-indicator-mobile-size: 0.9rem;

  --scroll-top-height-logocentered: calc(var(--header-bottomrow-height-logocentered) + var(--highlight-top-border-size) + var(--spacer-xl));
  --scroll-top-height: calc(var(--header-bottomrow-height) + var(--highlight-top-border-size) + var(--spacer-xl));

  --icon-button-size: 2.5rem;

  --mobile-nav-item-height: 4rem;

  --banner-text-layer-margin: #{remValue(80)};
  --breadcrumb-height: 1.5rem;

  --footer-height: #{remValue(100)};

  --lang-nav-button-size: 1rem;

  --highlight-top-border-size: 0.25rem;

  --avatar-circle-size: 5rem;

  --loadspinner-size: var(--info-circle-size);
  --loadspinner-size-large: #{remValue(300)};
  --loadspinner-border-size: #{remValue(4)};
  --loadspinner-border-size-large: #{remValue(8)};

  @media (min-width: breakpoints.$large) {
    --chevron-icon-size-menu: #{remValue(37)};
    --chevron-icon-size-standalone: 2.3rem;
  }

  @media (min-width: breakpoints.$extra-extra-large) {
    --chevron-icon-size-menu: #{remValue(40)};
    --chevron-icon-size-standalone: 2.5rem;
  }
}
