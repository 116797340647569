@use '../../../../scss/functions' as *;
@use '../../../../scss/settings/breakpoints';

.plf-header {
  position: sticky;
  top: calc((-1) * (#{var(--highlight-top-border-size)}) - 1px);
  z-index: z-index(page-layout, header);

  @media (min-width: breakpoints.$large) {
    top: calc((-1) * (#{var(--header-toprow-height)} + #{var(--highlight-top-border-size)}));
  }

  @media (min-width: breakpoints.$extra-extra-large) {
    border-top-width: 0.277777rem;
    top: calc((-1) * (#{var(--header-toprow-height)} + 0.277777rem));
  }

  &.-logocentered {
    border-top: 0;
    top: 0;
  }
}

.plf-header-slim {
  background: var(--color-white);
  position: sticky;
  top: calc(((-1) * var(--header-bottomrow-height) + var(--spacer-s)));
  z-index: z-index(page-layout, header);
}
