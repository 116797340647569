@use '../../../../scss/functions' as *;

.plf-content {
  position: relative;
}

.plf-main {
  z-index: z-index(page-layout, content);

  > :not(.sv-widget) > .plf-status-message {
    margin-bottom: 0;
    margin-top: var(--spacer-xl);
  }

  &.-anonymous {
    .plf-status-message {
      margin-left: var(--spacer-s);
    }

    .sv-widget .plf-status-message {
      margin-bottom: var(--spacer-xl);
    }
  }

  h1,
  h2,
  a {
    scroll-margin-top: var(--scroll-top-height);
  }
}

.plf-footer {
  z-index: z-index(page-layout, footer);
}

.plf-main,
.plf-footer {
  animation: content-smooth-in 0.8s 1s ease-out forwards;
  opacity: 0;
  width: 100%;
}

.sv-slot-content {
  padding: var(--spacer-l) var(--spacer-s);

  @media print {
    padding: 0;
  }
}

.sv-slot:not(.plf-frontpage-info) .sv-widget-content-content  h2, .sv-widget-simulation h2 {
  margin-top: var(--spacer-l);
}

.sv-widget-content {
  img {
    max-width: 100%;
  }

  .scrollable {
    overflow-x: auto;
  }
}
