.plf-widget-video {
  margin-bottom: var(--spacer-xs);

  .plf-video {
    height: 75%;
    padding-bottom: 44%;
    position: relative;
    width: 75%;

    iframe {
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  .uc-embedding-accept {
    background-color: var(--color-orange);
  }
}
