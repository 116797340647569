.sv-view-login-wrapper {
  &.sv-login {
    background-color: transparent;

    * {
      font-family: 'Roboto', sans-serif;
      font-size: 1rem;
      font-weight: 400;
      line-height: var(--font-line-height);
    }

    h1::after {
      height: 0;
    }

    a:hover,
    a:focus {
      color: var(--color-orange);
    }

    // stylelint-disable-next-line
    .sv-AdminSection-Title {
      font-family: 'Roboto', sans-serif;
      font-size: var(--font-display1-size);
      font-weight: 700;
      line-height: var(--font-display1-line-height);

      &::after {
        height: var(--headline-hightlight-line-height);
      }
    }

    // stylelint-disable-next-line
    .sv-AdminSection-Content {
      form {
        .ym-fbox-text {
          display: grid;
          grid-column-gap: var(--spacer-s);
          grid-template-columns: 1fr 3fr;

          label {
            padding-top: 0.5em;
          }

          input {
            background-color: var(--color-gray-light);
            border: 0;
            box-shadow: none;
            color: var(--color-gray-dark);
            padding: var(--spacer-xxs) var(--spacer-xs) calc(var(--spacer-xxs) - 1px);

            &:focus-visible {
              outline: none;
            }
          }
        }

        .ym-fbox-button {
          button[type='submit'] {
            align-items: center;
            background-color: var(--color-gray-light);
            border: 0;
            color: var(--color-dark);
            display: flex;
            float: right;
            justify-content: space-between;
            line-height: var(--font-line-height);
            padding: 0.5rem 1rem;

            &::after {
              content: url('/design/1/img/consultant-login/chevron_right.svg');
              line-height: 1rem;
              padding-left: var(--spacer-xs);
            }
          }
        }
      }
    }
  }
}
