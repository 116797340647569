// stylelint-disable meowtec/no-px
@use './breakpoints';
@use '../functions' as *;

// Small Spacing
:root {
  --font-display1-size: 2rem;
  --font-display1-line-height: 2.5rem;
  --font-display2-size: 1.5rem;
  --font-display2-line-height: 2rem;
  --font-display3-size: 1.125rem;
  --font-display3-line-height: 1.4rem;
  --font-title-size: 0.875rem;
  --font-small-text-size: 0.75rem;
  --font-size-root: 14px;
  --font-line-height: 1.5rem;
}

// Medium Spacing
@media (min-width: breakpoints.$medium) {
  :root {
    --font-size-root: 16px;
  }
}

// Extra Large Spacing
@media (min-width: breakpoints.$extra-extra-large) {
  :root {
    --font-size-root: 18px;
  }
}
