.sv-view-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.plf-content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // Overflow clip is needed to prevent translate load animation
  // from overflowing content and changing scrollbar size.
  // Most noticeable on pages with little content.
  overflow: clip;
  position: relative;
}

.plf-main {
  flex-grow: 1;
}

@page {
  margin: 2.5cm 2.5cm 2cm;
  size: auto;
}
