.w-uploadCourtDecisionStep {
  &__title {
    scroll-margin-top: var(--scroll-top-height);
  }

  &__buttons {
    margin-bottom: var(--spacer-xs);
    margin-top: var(--spacer-xs);
  }

  &__radioWrapper {
    margin-bottom: var(--spacer-xs);
  }
}
