@use '../../../../scss/settings/breakpoints';
@use '../../../../scss/settings/colors';

.plf-sections {
  .sv-slot-content {
    padding: 0;
  }

  .-gray {
    --hover-color: var(--color-white);
    background-color: var(--color-gray-light);
  }

  .-white {
    --hover-color: var(--color-gray-light);
    background-color: var(--color-white);
  }

  .a-section,
  .plf-frontpage-info {
    padding-bottom: var(--spacer-s);
    padding-top: var(--spacer-xl);
  }

  .plf-status-message .a-section,
  .plf-frontpage-info {
    margin-left: var(--spacer-s);
    margin-right: var(--spacer-s);
  }
}

.maintenance-warning-box {
  align-items: center;
  background: var(--color-orange);
  color: var(--color-white);
  height: 150px;
  margin-bottom: var(--spacer-xs);

  .maintenance-inner {
    padding: 0 var(--spacer-s);
  }

  .maintenance-title {
    margin-top: var(--spacer-xs);
  }

  .maintenance-description {
    font-size: var(--font-display3-size);
  }
}
