html {
  font-size: var(--font-size-root);
  text-size-adjust: 100%;
}

body {
  color: var(--color-text);
  font-weight: 400;
  line-height: var(--font-line-height);
}

h1 {
  margin-bottom: var(--spacer-m);
}

h1,
.display1 {
  font-size: var(--font-display1-size);
  font-weight: 700;
  line-height: var(--font-display1-line-height);
}

h2,
.display2 {
  font-size: var(--font-display2-size);
  font-weight: 700;
  line-height: var(--font-display2-line-height);
}

h3,
.display3 {
  font-size: var(--font-display3-size);
  font-weight: 300;
  line-height: var(--font-display3-line-height);
}

h4,
.display4 {
  font-size: var(--font-header4-size);
  font-weight: 700;
  line-height: var(--font-header4-size);
}

.title {
  font-size: var(--font-title-size);
  font-weight: 700;
  line-height: var(--font-title-size);
  text-transform: uppercase;
}

.small-text {
  font-size: var(--font-small-text-size);
  line-height: var(--font-small-text-size);
}

.light-text {
  font-weight: 300;
}

strong {
  font-weight: 700;
}

h1,
.headline {
  padding-bottom: 1.25rem;
  position: relative;
  margin-bottom: var(--spacer-s);

  &::after {
    background-color: var(--color-orange);
    bottom: 0;
    content: '';
    height: var(--headline-hightlight-line-height);
    left: 0;
    position: absolute;
    width: var(--headline-hightlight-line-width);
  }
}

a {
  color: var(--color-orange);
  text-decoration: none;
}
