:root {
  --color-white: #fff;
  --color-dark: #212529;

  --color-gray-light: #f8f9fa;
  --color-gray: #dee2e6;
  --color-gray-dark: #6c757d;

  --color-green-light: #67ddc1;
  --color-green: #46c3a6;
  --color-green-dark: #3a937e;

  --color-orange-light: #ffa060;
  --color-orange: #ff7200;
  --color-orange-dark: #bf4d00;

  --color-red-light: #ff7671;
  --color-red: #ff3b33;
  --color-red-dark: #c32822;

  --color-swissid-red: #eb0000;
}
