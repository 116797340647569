@mixin navigation-link() {
  &__link {
    color: var(--color-dark);
    display: flex;
    text-decoration: none;

    .a-btn-chevron {
      align-self: center;
      height: var(--chevron-icon-size-menu);
      padding-left: 0.5rem;
      width: var(--chevron-icon-size-menu);

      .a-btn-chevron__icon-up,
      .a-btn-chevron__icon-down {
        align-items: center;
        display: flex;
        height: var(--chevron-icon-size-menu);
      }
    }

    &:hover {
      color: var(--color-orange-dark);
    }

    &:active {
      color: var(--color-orange);
    }
  }
}
