@mixin consultantMessageListEntryChevronButton() {
  &__button {
    align-items: center;
    background-color: transparent;
    border: 0;
    display: flex;
    padding: 0;
    width: 100%;
    cursor: pointer;

    span {
      text-align: left;
    }

    &.-open {
      svg {
        fill: var(--color-orange);
      }
    }
  }

  &__chevron {
    transition: transform 0.3s;

    &.-rotate {
      transform: rotate(180deg);
    }
  }
}
