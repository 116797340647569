@use '../settings/breakpoints';

.container-lg,
.row,
.row > * {
  --bs-gutter-x: var(--size-gutter);
}

// ugly SV-Grid-Widget fix
.sv-widget-grid {
  .row > div {
    display: flex;

    > .sv-slot {
      display: flex;
      flex: 1;
      flex-direction: column;

      > div {
        display: flex;
        flex: 1;
      }
    }
  }
}

.gap-xxs {
  --bs-gutter-x: var(--spacer-xxs);

  > * {
    --bs-gutter-x: var(--spacer-xxs);
  }
}

.gap-lg-s {
  @media (min-width: breakpoints.$large) {
    --bs-gutter-x: var(--spacer-s);

    > * {
      --bs-gutter-x: var(--spacer-s);
    }
  }
}
