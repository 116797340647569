@use 'functions' as *;
@use '../js/templates/atoms/form-fields/select/index.scss';
@use 'settings/colors';

.inbox-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: var(--color-white);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10000020;
  border: 1px solid var(--color-gray);
  padding: 0.1875em;

  .inbox-dialog-titlebar {
    background-color: var(--color-gray);
    padding: 0.5em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    .inbox-dialog-close {
      padding: 0 1px 0 0;
      line-height: normal;
    }
  }

  .inbox-dialog-content {
    padding: 0.5em 1em;
  }

  .inbox-dialog-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.375em;
    padding: 0.3em 1em 0.5em 0.4em;
    margin-top: 0.4375em;

    button:disabled {
      background-color: var(--color-gray);
      opacity: .8;
      cursor: not-allowed;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark);
  opacity: 0.4;
  z-index: 10000019;
}
